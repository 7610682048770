#main-section .carousel-item img, #main-section .carousel-item .blurhash {
  object-fit: cover;
  object-position: center;
  height: 80vh !important;
  overflow: hidden;
}

/*#main-section .carousel-item .blurhash {
  height: 80vh !important;
}*/

#main-section .carousel-item:before {
  content: "";
  /*background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.50));*/
  background-image: linear-gradient(to bottom, rgba(76, 76, 107, 0.60), rgba(0, 0, 0, 0.50));
  /*background-image: linear-gradient(to bottom, rgba(119, 100, 242, 0.70), rgba(0, 0, 0, 0.92));*/
  display: block;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
}

#main-section .carousel-item h2 {
    font-family: Roboto, sans-serif;
}

#main-section .carousel-item button, #main-section .carousel-item a {
    font-family: Poppins, sans-serif;
    padding: 1rem 2rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    background-color: rgb(255, 190, 85);
}
