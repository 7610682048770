#privacy-policy .markdown {
  font-family: Poppins, sans-serif;
}

#privacy-policy .markdown ul {
  padding-bottom: 20px;
  padding-top: 0;
}

#privacy-policy .markdown p {
   font-size: 1.25rem;
}

#privacy-policy .close-button {
    padding: 1rem 4rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
	background-color: rgb(76, 76, 107);
}


