#not-found-page-title {
  background-color:#4C4C6B;
  color: #FFFFFF
}

#not-found-page-content {
  background-color:  rgb(235, 246, 239);
}

#not-found-page-content .header {
  font-size: 10rem
}

#not-found-page-content a {
  font-family: Poppins, sans-serif;
  padding: 1rem 4rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  background-color: rgb(76, 76, 107);
  border-color: rgb(76, 76, 107);
}