#try-it-out  {
  background-color: rgb(235, 246, 239);
}

#try-it-out .title {
	text-transform: uppercase;
}

#try-it-out .subtitle {
	text-transform: uppercase;
	color :   rgb(82, 82, 82);
	border-top: 5px solid rgb(159, 159, 159);
	padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    display: inline-block;
}

#try-it-out label {
	font-family: Poppins, sans-serif;
	font-size: 1.25rem;
	color: rgb(17, 24, 39);
}

#try-it-out .send-button {
	font-family: Poppins, sans-serif;
    padding: 1rem 4rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
	background-color: rgb(76, 76, 107);
	border-color: rgb(76, 76, 107);
}

#try-it-out .link {
	font-family: Poppins, sans-serif;
    padding: 0rem;
    font-size: 1.25rem;
    color: #923011;
}


#try-it-out input[type=text], #try-it-out input[type=email], #try-it-out input[type="tel"], #try-it-out textarea {
	background-color: rgba(0, 0, 0, 0.07);
    color: rgb(17, 24, 39);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
}



