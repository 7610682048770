#why-join-us  {
  background-color: rgb(235, 246, 239);
}

#why-join-us .title {
	text-transform: uppercase;
}

#why-join-us .subtitle {
	text-transform: uppercase;
	color :   rgb(82, 82, 82);
	border-top: 5px solid rgb(159, 159, 159);
	padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    display: inline-block;
}

#why-join-us .reason-icon {
	font-size: 7em;
}

#why-join-us .reason-content {
}
